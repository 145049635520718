import React, {
  createContext,
  ReactNode,
  useMemo,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { useMessageSingleContext } from '@contexts/message';
import MessageBodyStyles from '@styles/MessageBodyStyles';
import { useMessageContext } from '@contexts/MessageContext';

/**
 * These parameters are hard coded on MessageBodyShimmeringSkeleton.module.css
 */
const SKELETON_LINE_PADDING_TOP = 9;
const SKELETON_LINE_GAP = 9;
const SKELETON_LINE_HEIGHT = 17;

const LogPrefix = (): string => {
  const timestamp = ((Date.now() % 1000000) / 1000).toString().padStart(7, ' ');

  return `MessageBodyObserver ${timestamp}`;
};

enum MessageBodyState {
  HiddenRender = 'HiddenRender',
  InstantShrink = 'InstantShrink',
  Expanding = 'Expanding',
  Typing = 'Typing',
  OriginalRestored = 'OriginalRestored',
}

enum VideoReadyState {
  NotReady = 'NotReady',
  Ready = 'Ready',
  FailedToLoad = 'FailedToLoad',
}

interface MessageBodyInternalDataType {
  inWidth: number;
  inHeight: number;
  state: MessageBodyState;
  duration: number;
}

type messageBodyObserverProviderProps = {
  children: ReactNode;
};

type messageBodyObserverValue = null | {
  isOriginalStateRestored: boolean;
  skeletonLineCount: number;
  isVideoLoadingFinished: boolean;
  renderSkeletonLoader: boolean;
  styleModifierParent: Record<string, any>;
  styleModifierChild: Record<string, any>;
  videoLoadState: VideoReadyState;
  setVideoLoadState: Dispatch<SetStateAction<VideoReadyState>>;
  MessageBodyResizeHandler: (entry: ResizeObserverEntry) => void;
  setVideoDuration: (duration: number) => void;
};

const MessageBodyObserverContext = createContext<messageBodyObserverValue | null>(null);

const useMessageBodyObserverContext = () => {
  const messageBodyObserverContext = useContext(MessageBodyObserverContext);

  if (messageBodyObserverContext === undefined) {
    // throwing this error helps in development when the context is out of scope.
    throw new Error('useMessageBodyObserverContext must be inside a MessageBodyObserverProvider');
  }

  return messageBodyObserverContext;
};

const initialState = (renderAvatar: boolean): MessageBodyState => {
  if (renderAvatar) {
    return MessageBodyState.HiddenRender;
  } else {
    return MessageBodyState.OriginalRestored;
  }
};

const MessageBodyObserverProvider = ({ children }: messageBodyObserverProviderProps): JSX.Element => {
  const { moveScrollToBottom, scrollStateRef } = useMessageContext();

  const { renderAvatar, setShowMessagePercentage } = useMessageSingleContext();

  const [state, setState] = useState<MessageBodyState>(initialState(renderAvatar));
  const [videoLoadState, setVideoLoadState] = useState<VideoReadyState>(VideoReadyState.NotReady);

  const [skeletonLineCount, setSkeletonLineCount] = useState<number>(1);

  const internalData = useRef<MessageBodyInternalDataType>({
    state: initialState(renderAvatar),
    inWidth: -1,
    inHeight: -1,
    duration: 0,
  });

  const renderSkeletonLoader = useMemo(() => {
    return [MessageBodyState.InstantShrink, MessageBodyState.Expanding].includes(state);
  }, [state]);

  const styleModifierParent = useMemo(() => {
    let output: any = {};

    const data = internalData.current;

    if (state === MessageBodyState.HiddenRender) {
      // Render original message hidden, so we can measure original message dimention
      output = MessageBodyStyles.hiddenRender;
    } else if (state === MessageBodyState.InstantShrink) {
      // Shrink message to Line (nothing)
      output = {
        ...MessageBodyStyles.instantShrink,
        height: `${data.inHeight}px`,
      };
    } else if (state === MessageBodyState.Expanding) {
      // Expand message to original size from line
      output = {
        ...MessageBodyStyles.expanding,
        width: `${data.inWidth}px`,
        height: `${data.inHeight}px`,
      };
    } else if (state === MessageBodyState.Typing) {
      output = {
        ...MessageBodyStyles.typing,
        width: `${data.inWidth}px`,
        height: `${data.inHeight}px`,
      };
    } else if (state === MessageBodyState.OriginalRestored) {
      // restore original message
      output = MessageBodyStyles.originalRestored;
    }

    return output;
  }, [state, internalData]);

  const styleModifierChild = useMemo(() => {
    let output: any = {};

    if (state === MessageBodyState.Typing) {
      output = {
        width: '100%',
      };
    }

    return output;
  }, [state]);

  const isVideoLoadingFinished = useMemo(
    () => [VideoReadyState.Ready, VideoReadyState.FailedToLoad].includes(videoLoadState),
    [videoLoadState]
  );

  const isOriginalStateRestored = useMemo(() => state === MessageBodyState.OriginalRestored, [state]);

  const updateMessageBodyState = useCallback(
    (newState: MessageBodyState) => {
      console.debug(`${LogPrefix()} updateMessageBodyState: |${newState}|`);

      internalData.current.state = newState;

      setState(newState);

      // reset view ratio to 0
      if (newState === MessageBodyState.Typing) {
        setShowMessagePercentage(0);
      } else if (newState === MessageBodyState.OriginalRestored) {
        setShowMessagePercentage(1);
      }
    },
    [setShowMessagePercentage]
  );

  const setVideoDuration = useCallback((duration: number) => {
    const data = internalData.current;

    data.duration = duration || 0;
  }, []);

  const MessageBodyResizeHandler = useCallback(
    (entry: ResizeObserverEntry) => {
      const data = internalData.current;

      // if original message restored, do nothing
      if (data.state === MessageBodyState.OriginalRestored) {
        return;
      }

      const inWidth = entry.contentRect.width;
      const inHeight = entry.contentRect.height;

      // scroll to bottom, if it's locked to bottom
      if (scrollStateRef.current.lockedToBottom) {
        moveScrollToBottom();
      }

      if (data.state === MessageBodyState.HiddenRender) {
        data.inWidth = Math.ceil(inWidth);
        data.inHeight = Math.ceil(inHeight);

        updateMessageBodyState(MessageBodyState.InstantShrink);
      } else if (data.state === MessageBodyState.InstantShrink) {
        const newLineCount = Math.floor(
          (data.inHeight - SKELETON_LINE_PADDING_TOP) / (SKELETON_LINE_GAP + SKELETON_LINE_HEIGHT)
        );

        setSkeletonLineCount(Math.max(newLineCount, 1));
        updateMessageBodyState(MessageBodyState.Expanding);
      }
    },
    [updateMessageBodyState, moveScrollToBottom, scrollStateRef]
  );

  useEffect(() => {
    if (state !== MessageBodyState.Typing) return;

    let counter = 0;
    const SPEED = 50;
    const SYNC_ADJUSTMENT = 0.85;

    const durationMs = internalData.current?.duration * 1000 || 0;
    const totalSection = (durationMs * SYNC_ADJUSTMENT) / SPEED;

    if (totalSection <= 0) {
      return;
    }

    const timer = setInterval(() => {
      counter++;

      const sectionRatio = counter / totalSection;

      setShowMessagePercentage(sectionRatio);

      if (sectionRatio >= 1) {
        clearInterval(timer);
      }
    }, SPEED);

    return () => clearInterval(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (videoLoadState === VideoReadyState.Ready) {
      updateMessageBodyState(MessageBodyState.Typing);
    }

    // if avatar doesn't exist then restore original
    if (videoLoadState === VideoReadyState.FailedToLoad || renderAvatar === false) {
      updateMessageBodyState(MessageBodyState.OriginalRestored);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoLoadState, renderAvatar]);

  const contextValue = useMemo(
    () => ({
      isOriginalStateRestored,
      skeletonLineCount,
      isVideoLoadingFinished,
      renderSkeletonLoader,
      styleModifierParent,
      styleModifierChild,
      videoLoadState,
      setVideoLoadState,
      MessageBodyResizeHandler,
      setVideoDuration,
    }),
    [
      isOriginalStateRestored,
      skeletonLineCount,
      isVideoLoadingFinished,
      renderSkeletonLoader,
      styleModifierParent,
      styleModifierChild,
      videoLoadState,
      setVideoLoadState,
      MessageBodyResizeHandler,
      setVideoDuration,
    ]
  );

  return <MessageBodyObserverContext.Provider value={contextValue}>{children}</MessageBodyObserverContext.Provider>;
};

export { MessageBodyObserverProvider, useMessageBodyObserverContext, VideoReadyState };
