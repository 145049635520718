import React, { createContext, ReactNode, useMemo, useContext, useState, Dispatch, SetStateAction } from 'react';
import { IMessage } from '@interfaces/Conversation';
import { AccessType } from '@common/AccessType';
import { IUserData, IUserAction, IUserChatAction, TFieldAttribute, INotification } from '@interfaces/Conversation';
import { uiTranslationController } from '@common/common';
import { useAvatarContext } from '@contexts/avatar';
import { useMessageContext } from '@contexts/MessageContext';
import { useErrandContext } from '@contexts/ErrandContext';

type MessageSingleProviderProps = {
  children: ReactNode;
  index: number;
  showDate: boolean;
  showSentiment: boolean;
};

type MessageSingleValue = null | {
  messageIntendedAudienceLength: number;
  setShowMessagePercentage: Dispatch<SetStateAction<number>>;
  isEditing: boolean;
  isPrivateMessageContent: boolean;
  isDeletedMessageContent: boolean;
  showSentiment: boolean;
  showDate: boolean;
  dispMessage: string;
  isTranslated: boolean;
  renderAvatar: boolean;
  isLastMessage: boolean;
  index: number;
  message: IMessage;
  messageId: string;
  messageChat: string;
  messageUserId: string;
  messageSentByCurrentUser: boolean;
  messageAlignByCurrentUser: boolean;
  messageDetectedLanguage: string;
  messageLocalizedLanguage: string;
  messageSearchWords: string;
  messageVisible: boolean;
  messageIntendedAudience: string[];
  messageAccessType: AccessType;
  messageMessage: string;
  messageMessageStatus: string;
  messageIcon: string;
  messageSender: IUserData;
  messageUserAction: IUserAction;
  messageAction: IUserChatAction;
  messageSenderId: string;
  messageUserActionId: string;
  messageActionFieldAttribute: TFieldAttribute;
  messageActionFieldAttributeDescription: string;
  messageCreatedAt: Date;
  messageNotifications: INotification[];
  messageNotificationsLength: number;
  messageActionDescription: string;
  messageMessageType: string;
  messageSentiment: string;
  messageReplyToOriginalText: string;
  messageSenderNickname: string;
  messageSenderFirstname: string;
  messageSenderType: string;
  messageReplyTo: {
    chat: string;
    originalMessage: string;
    originalText: string;
  };
  messageInvitation: string;
  messageReferrerFirstName: string;
  messageRefereeFullName: string;
  messageRefereeChatId: string;
  messageClickToJoin: string;
};

const MessageSingleContext = createContext<MessageSingleValue>(null);

const useMessageSingleContext = () => {
  const messageSingleContext = useContext(MessageSingleContext);

  if (messageSingleContext === null) {
    // Throwing this error helps in development when the context is out of scope.
    throw new Error('useMessageSingleContext must be inside a MessageSingleProvider');
  }

  return messageSingleContext;
};

// Capitalize the component name
const MessageSingleProvider = ({
  children,
  index,
  showDate,
  showSentiment,
}: MessageSingleProviderProps): JSX.Element => {
  const { errand } = useErrandContext();

  const { isPrivate, editMessageId } = useMessageContext();

  const { canRenderAvatarForMessage } = useAvatarContext();

  const { privateMessages, messages } = errand;

  const [showMessagePercentage, setShowMessagePercentage] = useState<number>(1);

  const messageBuffer = useMemo((): IMessage[] => {
    return isPrivate ? privateMessages : messages;
  }, [isPrivate, messages, privateMessages]);

  const isLastMessage = useMemo((): boolean => !messageBuffer?.[index + 1], [messageBuffer, index]);
  const message = useMemo((): IMessage => messageBuffer?.[index], [messageBuffer, index]);

  // translation related parameters
  const { dispMessage, isTranslated } = useMemo(() => uiTranslationController(message || ''), [message]);

  const partialDispMessage = useMemo(() => {
    const originalMessage = dispMessage;

    if (typeof originalMessage !== 'string') return originalMessage;

    if (showMessagePercentage <= 0) return '';
    if (showMessagePercentage >= 1) return originalMessage;

    const lengthToExtract = Math.round(showMessagePercentage * originalMessage.length);

    const output = originalMessage.substring(0, lengthToExtract);

    return output;
  }, [dispMessage, showMessagePercentage]);

  // derived from directly message obj
  const messageId = useMemo(() => message?._id, [message?._id]);
  const messageChat = useMemo(() => message?.chat, [message?.chat]);
  const messageUserId = useMemo(() => message?.userId, [message?.userId]);
  const messageSentByCurrentUser = useMemo(() => message?.sentByCurrentUser, [message?.sentByCurrentUser]);
  const messageAlignByCurrentUser = useMemo(() => message?.alignByCurrentUser, [message?.alignByCurrentUser]);
  const messageDetectedLanguage = useMemo(() => message?.detectedLanguage, [message?.detectedLanguage]);
  const messageLocalizedLanguage = useMemo(() => message?.localizedLanguage, [message?.localizedLanguage]);
  const messageSearchWords = useMemo(() => message?.searchWords, [message?.searchWords]);
  const messageVisible = useMemo(() => message?.visible, [message?.visible]);
  const messageIntendedAudience = useMemo(() => message?.intendedAudience, [message?.intendedAudience]);
  const messageAccessType = useMemo(() => message?.accessType, [message?.accessType]);
  const messageMessage = useMemo(() => message?.message, [message?.message]);
  const messageMessageStatus = useMemo(() => message?.messageStatus, [message?.messageStatus]);
  const messageIcon = useMemo(() => message?.icon, [message?.icon]);
  const messageSender = useMemo(() => message?.sender, [message?.sender]);
  const messageUserAction = useMemo(() => message?.userAction, [message?.userAction]);
  const messageAction = useMemo(() => message?.action, [message?.action]);
  const messageCreatedAt = useMemo(() => message?.createdAt, [message?.createdAt]);
  const messageNotifications = useMemo(() => message?.notifications, [message?.notifications]);
  const messageMessageType = useMemo(() => message?.messageType, [message?.messageType]);
  const messageReplyTo = useMemo(() => message?.replyTo, [message?.replyTo]);
  const messageSentiment = useMemo(() => message?.sentiment, [message?.sentiment]);
  const messageSenderType = useMemo(() => message?.senderType, [message?.senderType]);
  const messageInvitation = useMemo(() => message?.invitation, [message?.invitation]);
  const messageReferrerFirstName = useMemo(() => message?.referrerFirstName, [message?.referrerFirstName]);
  const messageRefereeFullName = useMemo(() => message?.refereeFullName, [message?.refereeFullName]);
  const messageRefereeChatId = useMemo(() => message?.refereeChatId, [message?.refereeChatId]);
  const messageClickToJoin = useMemo(() => message?.clickToJoin, [message?.clickToJoin]);

  const messageIntendedAudienceLength = useMemo(
    () => messageIntendedAudience?.length,
    [messageIntendedAudience?.length]
  );

  const messageSenderNickname = useMemo(() => messageSender?.nickname, [messageSender?.nickname]);
  const messageSenderFirstname = useMemo(() => messageSender?.firstname, [messageSender?.firstname]);

  const messageReplyToOriginalText = useMemo(() => messageReplyTo?.originalText, [messageReplyTo?.originalText]);

  const messageNotificationsLength = useMemo(() => messageNotifications?.length, [messageNotifications?.length]);
  const messageSenderId = useMemo(() => messageSender?._id, [messageSender?._id]);
  const messageUserActionId = useMemo(() => messageUserAction?._id, [messageUserAction?._id]);

  const messageActionDescription = useMemo(() => messageAction?.description, [messageAction?.description]);
  const messageActionFieldAttribute = useMemo(() => messageAction?.fieldAttribute, [messageAction?.fieldAttribute]);

  const messageActionFieldAttributeDescription = useMemo(
    () => messageActionFieldAttribute?.description,
    [messageActionFieldAttribute?.description]
  );

  const isEditing = useMemo(() => editMessageId === messageId, [editMessageId, messageId]);

  const renderAvatar = useMemo((): boolean => {
    return canRenderAvatarForMessage(messageId, isLastMessage, messageMessageType, messageSenderId);
  }, [isLastMessage, messageMessageType, messageId, messageSenderId, canRenderAvatarForMessage]);

  const isDeletedMessageContent = useMemo(() => {
    return messageMessageStatus === 'deleted';
  }, [messageMessageStatus]);

  const isPrivateMessageContent = useMemo(() => {
    return !isPrivate && messageAccessType === AccessType.private;
  }, [isPrivate, messageAccessType]);

  const contextValue = useMemo(
    () => ({
      messageIntendedAudienceLength,
      setShowMessagePercentage,
      isEditing,
      isPrivateMessageContent,
      isDeletedMessageContent,
      showSentiment,
      showDate,
      dispMessage: partialDispMessage,
      isTranslated,
      renderAvatar,
      isLastMessage,
      index,
      message,
      messageId,
      messageChat,
      messageUserId,
      messageSentByCurrentUser,
      messageAlignByCurrentUser,
      messageDetectedLanguage,
      messageLocalizedLanguage,
      messageSearchWords,
      messageVisible,
      messageIntendedAudience,
      messageAccessType,
      messageMessage,
      messageMessageStatus,
      messageIcon,
      messageSender,
      messageUserAction,
      messageAction,
      messageSenderId,
      messageUserActionId,
      messageActionFieldAttribute,
      messageActionFieldAttributeDescription,
      messageCreatedAt,
      messageNotifications,
      messageNotificationsLength,
      messageActionDescription,
      messageMessageType,
      messageReplyTo,
      messageSentiment,
      messageReplyToOriginalText,
      messageSenderNickname,
      messageSenderFirstname,
      messageSenderType,
      messageInvitation,
      messageReferrerFirstName,
      messageRefereeFullName,
      messageRefereeChatId,
      messageClickToJoin,
    }),
    [
      messageIntendedAudienceLength,
      setShowMessagePercentage,
      isEditing,
      isPrivateMessageContent,
      isDeletedMessageContent,
      showSentiment,
      showDate,
      partialDispMessage,
      isTranslated,
      renderAvatar,
      isLastMessage,
      index,
      message,
      messageId,
      messageChat,
      messageUserId,
      messageSentByCurrentUser,
      messageAlignByCurrentUser,
      messageDetectedLanguage,
      messageLocalizedLanguage,
      messageSearchWords,
      messageVisible,
      messageIntendedAudience,
      messageAccessType,
      messageMessage,
      messageMessageStatus,
      messageIcon,
      messageSender,
      messageUserAction,
      messageAction,
      messageSenderId,
      messageUserActionId,
      messageActionFieldAttribute,
      messageActionFieldAttributeDescription,
      messageCreatedAt,
      messageNotifications,
      messageNotificationsLength,
      messageActionDescription,
      messageMessageType,
      messageReplyTo,
      messageSentiment,
      messageReplyToOriginalText,
      messageSenderNickname,
      messageSenderFirstname,
      messageSenderType,
      messageInvitation,
      messageReferrerFirstName,
      messageRefereeFullName,
      messageRefereeChatId,
      messageClickToJoin,
    ]
  );

  return <MessageSingleContext.Provider value={contextValue}>{children}</MessageSingleContext.Provider>;
};

export { MessageSingleProvider, useMessageSingleContext };
