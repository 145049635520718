// MorphContext.js
import { ChatType } from '@common/ChatType';
import { CReqState } from '@common/msgUtils';
import { TBorrower } from '@mTypes/TBorrower';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import React, { Dispatch, MutableRefObject, SetStateAction, createContext, useContext } from 'react';

type TMorphContext = {
  contextSetSelectedContact: Dispatch<SetStateAction<any>>;
  setSelectedBorrowers: Dispatch<SetStateAction<Set<TBorrower>>>;
  selectedBorrowers: Set<TBorrower>;
  setBorrowerList: Dispatch<SetStateAction<Array<TBorrower>>>;
  borrowerList: Array<TBorrower>;
  sendButtonRef: MutableRefObject<{ update: (newInputValue: string) => void }>;
  canSendText: MutableRefObject<boolean>;
  openSlotMachine: () => Promise<boolean>;
  loanProducts: Array<LoanProduct>;
  setLoanProducts: Dispatch<SetStateAction<Array<LoanProduct>>>;
  hoveredLoanProduct: LoanProduct;
  setHoveredLoanProduct: Dispatch<SetStateAction<LoanProduct>>;
  chosenLoanProduct: LoanProduct;
  setChosenLoanProduct: Dispatch<SetStateAction<LoanProduct>>;
  msgReqStateRef: MutableRefObject<CReqState>;
  setFieldAttribute: Dispatch<SetStateAction<string>>;
  selectedPrices: { [key: string]: { lockPeriod: number; rate: number, price: number } };
  setSelectedPrices: Dispatch<SetStateAction<{ [key: string]: { lockPeriod: number; rate: number, price: number } }>>;
  hideCalendarMonth: boolean;
  setHideCalendarMonth: Dispatch<SetStateAction<boolean>>;
  hideTime: boolean;
  setHideTime: Dispatch<SetStateAction<boolean>>;
  selectedTimezoneIndex: number;
  setSelectedTimezoneIndex: Dispatch<SetStateAction<number>>;
  setIsInvalidWorkshopDate: Dispatch<SetStateAction<boolean>>;
  errandType: ChatType;
  isSending: boolean;
  selectedFiles: Array<File>;
  setSelectedFiles: (files: File[]) => void;
  attachmentTabText: string;
  setAttachmentTabText: Dispatch<SetStateAction<string>>;
  isUploadingDocs: boolean;
  setIsUploadingDocs: Dispatch<SetStateAction<boolean>>;
  languageSelectorIndex: number;
  setlanguageSelectorIndex: Dispatch<SetStateAction<number>>;
};

const MorphContext = createContext<TMorphContext | null>(null);

export default MorphContext;

export const useMorphContext = () => useContext<TMorphContext>(MorphContext);
