/**
 * @file This component is a stylized Box component
 * for styling the Mini Chat Menu.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { styled } from '@mui/system';
import Input from '@mui/material/Input';
import { MorphType } from '@common/MorphType';

// NOTICE: theme.palette variables should follow the below format
// "componentName" { "emmetShortcutForAttributeAndTargetClassName": "hex or rgba only" }
// Get emmetShortcut from this cheat sheet: https://docs.emmet.io/cheat-sheet/
// Try to avoid passing props and use dynamic classes instead so that the css will be static

export const StyledInputBase = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'morphType',
})<any>(
  
  ({ theme, morphType }) => `
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
  overflow: auto;
  // scrollbar-width: thin;
  flex: 1;
  padding-right: 1px;
  color: var(--gray920);
  
  & textarea {
    &.Mui-disabled {
      ::placeholder{
        text-fill-color: 
          ${morphType === MorphType.UserPromptsMenu || 
            morphType === MorphType.ShareCustomLink ||
            morphType === MorphType.CalendarMonth ||
            morphType === MorphType.DOB ||
            morphType === MorphType.Time ||
            morphType === MorphType.VideoListMenu
            ? 'var(--customLinkPlaceholder)' : 'initial'};
        opacity: 
          ${morphType === MorphType.UserPromptsMenu || 
            morphType === MorphType.ShareCustomLink ||
            morphType === MorphType.CalendarMonth ||
            morphType === MorphType.DOB ||
            morphType === MorphType.Time ||
            morphType === MorphType.VideoListMenu
            ? 
            '1' : 'initial'};
      }
    &.Mui-disabled{
      text-fill-color: 
          ${morphType === MorphType.CalendarMonth ||
            morphType === MorphType.DOB ||
            morphType === MorphType.Time
            ? 'var(--customLinkPlaceholder)' : 'initial'};
      display: ${[ MorphType.CreditRepairWelcome, MorphType.RefinanceCalculatorWelcome].includes(morphType) ?
                 'none' : 'initial'};
    }
    ::placeholder{
      bottom: ${morphType === MorphType.Recording ? '5px' : '0'};
      position: ${morphType === MorphType.Recording ? 'relative' : 'static'};
      bottom: ${morphType === MorphType.Attachment ? 'relative' : '0'};
      position: ${morphType === MorphType.Attachment ? 'relative' : 'static'};
      text-fill-color: var(--gray600);
    }
    // Scrollbar styling applied globally in index.css
    // &::-webkit-scrollbar {
    //   width: .4em;
    // }
    // &::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 6px var(--shadow000);
    //   webkit-box-shadow: inset 0 0 6px var(--shadow000);
    // }
    // &::-webkit-scrollbar-thumb {
    //   background-color: var(--shadow110);
    //   outline: 1px solid slategrey;
    //   border-radius: .2em;
    // }
  }
  `
);

