import React,  { Dispatch, MutableRefObject, SetStateAction, createContext, useContext } from 'react';
import { Sentiments } from '@mTypes/TSentiment';
import type { InputState } from '@components/FooterInputStorage/storage';

/**
 * This context will store data that's unique to the conversation footer
 *
 * This includes elements related to the following:
 * selectedContact and setSelectedContact for Thin Client's contact sync
 *
 */

// type EmailAddresses = {
//     label?: string,
//     email: string,
//     _id: number,
// };

// type PhoneNumbers = {
//     label?: string,
//     number: string,
//     _id: number
// }

type Contact = {
  _id: string;
  givenName: string;
  familyName: string;
  displayName: string;
};

// export type Contact = {
//     _id?: string,
//     recordID?: string,
//     userId?: string,
//     company?: string,
//     department?: string,
//     displayName?: string,
//     emailAddresses?: EmailAddresses[],
//     familyName?: string,
//     givenName?: string,
//     hostDeviceId?: string,
//     imAddresses?: [],
//     jobTitle?: string,
//     middleName?: string,
//     phoneNumbers?: PhoneNumbers[],
//     postalAddresses?: [],
//     prefix?: string,
//     suffix?: string,
//     thumbnailData?: string
// };

export interface IAppsMenuState { 
  isOpened: boolean; 
  shown: boolean;
  displayed: boolean;
  listShown: boolean;
  searchMode: boolean;
  searchView: boolean;
  searchWasCancelled: boolean;
}
export interface IFooterContext {
  selectedContact?: Contact; // object containing the user's selected contact
  textInputEnabled: MutableRefObject<boolean>;
  sendButtonRef: MutableRefObject<{ update: (newInputValue: string) => void, startAnimation(): void, handleSubmit(): void, focus(): void } & HTMLButtonElement>;
  needToShowFormConsentRef: MutableRefObject<boolean>;
  sentimentPreview: Sentiments;
  setSentimentPreview: (value: Sentiments) => void;
  chatInputFieldRef: MutableRefObject<null | { update: (newInputValue: string | InputState) => void; unformattedValue: string }>;
  handleOtpError: boolean;
  setHandleOtpError: (value: boolean) => void;
  appsMenuState: IAppsMenuState,
  setAppsMenuState: React.Dispatch<React.SetStateAction<IAppsMenuState>>
  footerDimensionsState: {
    inputContainer: { width: number; height: number },
    conversationFooter: { width: number; height: number },
    conversationFooterRow: { width: number; height: number }
  };
  captureTwinMinutesPurchaseAmount: boolean;
  setCaptureTwinMinutesPurchaseAmount: Dispatch<SetStateAction<boolean>>;
}

export const FooterContext = createContext<IFooterContext | null>(null);

export const useFooterContext = () => useContext<IFooterContext>(FooterContext);