/**
 * AvatarProvider Component
 *
 * This component provides context and manages the state of avatar ownership within the application.
 * It allows multiple child components to observe and request ownership of a video avatar.
 * The avatar can be owned by one message at a time, and only the current owner can release it.
 * The component handles avatar state transitions, including whether the avatar is active, idle, or in an error state.
 *
 * The context provides functions for:
 * 1. Claiming avatar ownership (`claimAvatarOwnership`).
 * 2. Releasing avatar ownership (`releaseAvatarOwnership`).
 * 3. Checking if a specific message is the current avatar owner (`isAvatarInActiveMap`).
 * 4. Determining if the avatar should be active based on message and participant state (`isAvatarActive`).
 * 5. Checking if the current message can render the avatar (`canRenderAvatarForMessage`).
 *
 * This provider ensures that only the appropriate participants can interact with the avatar
 * based on certain conditions such as message type, participant role, and morph type.
 */

import { useParticipantContext } from '@contexts/participant';
import { useUserContext } from '@contexts/user';
import { ValidatorFunctions } from '@common/Validators';
import { AvatarMutexState, AvatarInstanceState } from '@common/AvatarType';
import React, { createContext, ReactNode, useCallback, useMemo, useContext, useRef, useState } from 'react';

const LogPrefix = (): string => {
  const timestamp = ((Date.now() % 1000000) / 1000).toString().padStart(7, ' ');

  return `TwinAvatar ${timestamp}`;
};

interface AvatarInternalDataType {
  mutexLockOwnerId: string;
  activeAvatars: Map<string, AvatarInstanceState>;
}

type AvatarContextValueType = null | {
  avatarMutexState: AvatarMutexState;
  isAvatarLocked: boolean;
  releaseAvatarOwnership: (messageId: string) => void;
  claimAvatarOwnership: (messageId: string) => boolean;
  isAvatarInstanceState: (messageId: string, newState: AvatarInstanceState) => boolean;
  updateAvatarInstanceState: (messageId: string, newState: AvatarInstanceState) => void;
  canRenderAvatarForMessage: (
    messageId: string,
    isLastMessage: boolean,
    messageType: string,
    messageSenderId: string | undefined
  ) => boolean;
};

type AvatarProviderProps = {
  children: ReactNode;
};

const AvatarContext = createContext<AvatarContextValueType | null>(null);

// Custom hook to access AvatarContext value
const useAvatarContext = () => {
  const avatarContext = useContext(AvatarContext);

  if (ValidatorFunctions.isUndefinedOrNull(avatarContext)) {
    // Error handling if the hook is called outside of AvatarProvider
    throw new Error('useAvatarContext must be inside a AvatarProvider');
  }

  return avatarContext;
};

const AvatarProvider = ({ children }: AvatarProviderProps): JSX.Element => {
  // Function to access active AI operators excluding AngelAI from context
  const { activeAIOperatorsExcludingAngelAI } = useParticipantContext();

  // Function to access user context and extract operator status and user ID
  const { isOperator } = useUserContext();

  // Avatar lock state - initialized to unlocked
  const [avatarMutexState, setAvatarMutexState] = useState<AvatarMutexState>(AvatarMutexState.Unlocked);

  // Keep track of active avatar count
  const [activeAvatarsCount, setActiveAvatarsCount] = useState<number>(0);

  // Internal data reference that holds avatar ownership and state information
  const internalDataRef = useRef<AvatarInternalDataType>({
    mutexLockOwnerId: '',
    activeAvatars: new Map<string, AvatarInstanceState>(),
  });

  // Memoized value that indicates whether the avatar is currently locked
  const isAvatarLocked = useMemo((): boolean => {
    return avatarMutexState === AvatarMutexState.Locked;
  }, [avatarMutexState]);

  /**
   * Checks if the provided message has a specific avatar state.
   * @param messageId - ID of the message to check
   * @param newState - The state to compare against
   * @returns true if the avatar state for the message matches the provided state, otherwise false
   */
  const isAvatarInstanceState = useCallback((messageId: string, newState: AvatarInstanceState): boolean => {
    const data = internalDataRef.current;
    return data.activeAvatars.get(messageId) === newState;
  }, []);

  /**
   * Updates the avatar instance state for a specific message ID.
   * @param messageId - The ID of the message whose avatar state needs to be updated
   * @param newState - The new state to assign to the avatar instance
   */
  const updateAvatarInstanceState = useCallback((messageId: string, newState: AvatarInstanceState) => {
    const data = internalDataRef.current;
    const oldState = data.activeAvatars.get(messageId);

    // Update the avatar state or remove it if it's pending unmount or already unmounted
    data.activeAvatars.set(messageId, newState);
    if ([AvatarInstanceState.PendingUnmount, AvatarInstanceState.Unmounted].includes(newState)) {
      data.activeAvatars.delete(messageId);
    }

    setActiveAvatarsCount(data.activeAvatars.size);

    console.debug(
      `${LogPrefix()} updateAvatarInstanceState messageId: |${messageId}| oldState: |${oldState}| newState: |${newState}| activeAvatarsCount: |${
        data.activeAvatars.size
      }|`
    );
  }, []);

  /**
   * Requests avatar ownership for a specific message ID if no other message currently owns it.
   * @param messageId - The ID of the message requesting avatar ownership
   * @returns true if ownership was successfully claimed, otherwise false
   */
  const claimAvatarOwnership = useCallback(
    (messageId: string) => {
      const data = internalDataRef.current;

      // Ownership is only allowed if no other message owns the avatar
      if (data.mutexLockOwnerId || avatarMutexState !== AvatarMutexState.Unlocked) return false;

      console.debug(`${LogPrefix()} |${messageId}| claimAvatarOwnership`);

      internalDataRef.current.mutexLockOwnerId = messageId;
      setAvatarMutexState(AvatarMutexState.Locked);

      return true;
    },
    [avatarMutexState]
  );

  /**
   * Releases avatar ownership for a specific message ID.
   * Ownership can only be released by the current owner of the avatar.
   * @param messageId - The ID of the message releasing avatar ownership
   */
  const releaseAvatarOwnership = useCallback((messageId: string): void => {
    // Only release if the provided message is the current owner
    if (internalDataRef.current.mutexLockOwnerId !== messageId) {
      return;
    }

    console.debug(`${LogPrefix()} |${messageId}| releaseAvatarOwnership`);

    internalDataRef.current.mutexLockOwnerId = '';
    setAvatarMutexState(AvatarMutexState.Unlocked);
  }, []);

  /**
   * Checks if a given message ID is currently in the active avatar state map.
   * @param messageId - The message ID to check
   * @returns true if the message has an active avatar state, otherwise false
   */
  const isAvatarInActiveMap = useCallback(
    (messageId: string): boolean => {
      // Recalculate isAvatarInActiveMap whenever the active avatar map size changes
      // to ensure accurate avatar visibility for messages.
      void activeAvatarsCount;

      const data = internalDataRef.current;
      const mapValue = data.activeAvatars.get(messageId);

      return ValidatorFunctions.isNotUndefinedNorNull(mapValue);
    },
    [activeAvatarsCount]
  );

  /**
   * Determines whether the avatar should be active for a specific message.
   * This decision is based on multiple conditions such as message sender, morph type, and message type.
   * @param isLastMessage - Boolean indicating if the message is the last in the sequence
   * @param messageSenderId - The ID of the message sender
   * @returns true if the avatar can be active for the message, otherwise false
   */
  const isAvatarActive = useCallback(
    (isLastMessage: boolean, messageSenderId?: string): boolean => {
      // Avatar can only be active for the last message
      if (!isLastMessage) {
        return false;
      }

      // Operators should not have an avatar
      if (isOperator) {
        return false;
      }

      // Ignore messages without a sender ID (user's own message doesn't have sender ID)
      if (ValidatorFunctions.isUndefinedOrNull(messageSenderId)) {
        return false;
      }

      // Avatar is Message sender
      if (activeAIOperatorsExcludingAngelAI.find((x) => x?.userData?._id === messageSenderId)) {
        return true;
      }

      return false;
    },
    [isOperator, activeAIOperatorsExcludingAngelAI]
  );

  /**
   * Determines if the avatar can be rendered for a specific message.
   * This includes checking whether the message ID is in the active map or if the avatar is active.
   * @param messageId - The ID of the message
   * @param isLastMessage - Boolean indicating if the message is the last in the sequence
   * @param messageType - The type of the message
   * @param messageSenderId - The ID of the sender of the message
   * @returns true if the avatar can be rendered for the message, otherwise false
   */
  const canRenderAvatarForMessage = useCallback(
    (messageId: string, isLastMessage: boolean, messageType: string, messageSenderId: string | undefined): boolean => {
      return isAvatarInActiveMap(messageId) || isAvatarActive(isLastMessage, messageSenderId);
    },
    [isAvatarInActiveMap, isAvatarActive]
  );

  // Memoized context value to optimize re-renders and prevent unnecessary updates
  const contextValue = useMemo(
    () => ({
      isAvatarLocked,
      avatarMutexState,
      claimAvatarOwnership,
      releaseAvatarOwnership,
      canRenderAvatarForMessage,
      isAvatarInstanceState,
      updateAvatarInstanceState,
    }),
    [
      isAvatarLocked,
      avatarMutexState,
      claimAvatarOwnership,
      releaseAvatarOwnership,
      canRenderAvatarForMessage,
      isAvatarInstanceState,
      updateAvatarInstanceState,
    ]
  );

  return <AvatarContext.Provider value={contextValue}>{children}</AvatarContext.Provider>;
};
export { AvatarProvider, useAvatarContext };
